<template>
  <div class="coupon-history" :class="{skeleton: !state.loaded }">
    <div class="title font-md">
      <b>{{ state.couponName }}</b>
    </div>
    <div class="desc font-sm">
      <span>이 쿠폰으로 사용 가능한 프로젝트 목록이에요.</span>
    </div>
    <div class="content">
      <ul class="tight">
        <li v-for="(p, idx) in state.projects" :key="idx">
          <router-link :to="'/reward/' + p.projectSeq" title="프로젝트로 이동하시려면 클릭하세요.">
            <span class="img" :style="{ backgroundImage: state.loaded ? `url(${p.filePath})` : '' }"></span>
          </router-link>
          <div class="title" title="프로젝트로 이동하시려면 클릭하세요.">
            <router-link :to="'/reward/' + p.projectSeq">{{ p.projectName }}</router-link>
          </div>
          <div class="date font-sm">
            <span>
              <span>{{ $lib.getDateFormat(p.investStartDate, "yyyy-MM-dd") }}</span>
              <span> ~ </span>
              <span>{{ $lib.getDateFormat(p.investEndDate, "yyyy-MM-dd") }}</span>
            </span>
          </div>
          <div class="act">
            <router-link :to="'/reward/' + p.projectSeq" class="btn btn-default font-xs">
              <span>프로젝트로 이동</span>
            </router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "modalCouponProjects";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "md"});

      const args = {
        couponId: modalParams.couponId,
        couponScope: modalParams.couponScope
      };

      state.couponName = modalParams.couponName;
      state.loaded = false;

      http.get("/api/coupons/projects", args).then(({data}) => {
        state.loaded = true;

        if (Array.isArray(data?.body?.list)) {
          state.projects = (data.body.list);
        }
      }).catch(httpError(() => {
        store.commit("closeModal", {name: component.name});
      }));
    });

    const state = reactive({
      loaded: false,
      couponName: "",
      projects: [{
        filePath: "",
        projectName: "Wait a moment",
        investStartDate: "20211227",
        investEndDate: "20211227"
      }]
    });

    const modalParams = store.getters.modalParams(component);

    return {component, state};
  },
});
</script>

<style lang="scss" scoped>
.coupon-history {
  background: #fff;
  padding: $px25;
  border-radius: $px4;

  .title {
    margin-bottom: $px6;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: $px15;

    a {
      font-weight: 500;
    }
  }

  .desc {
    margin-bottom: $px25;
    color: #666;
  }

  .content {
    ul {
      li {
        padding: $px30 $px176 $px30 $px170;
        background-repeat: no-repeat;
        background-position: left;
        position: relative;
        margin-bottom: $px15;
        overflow: hidden;
        border: $px1 solid #eee;
        border-radius: $px4;
        overflow: hidden;

        > a {
          position: absolute;
          top: 0;
          left: 0;
          border-radius: $px4 0 0 $px4;
          font-size: 0;
          width: $px150;

          .img {
            background-color: $colorBackground;
            padding-top: $ratio43Percent;
            width: 100%;
          }
        }

        .date {
          color: #767676;
        }

        .act {
          position: absolute;
          top: 50%;
          right: $px20;
          transform: translateY(-50%);

          a {
            padding: $px14;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &.skeleton {
    .title > b,
    .desc > span {
      @include skeleton;
    }

    .content ul li {
      .img,
      .title > a,
      .date > span,
      .act a {
        @include skeleton;
      }

      .act a i {
        visibility: hidden;
      }
    }
  }

  @media(max-width: 767px) {
    .content ul li {
      padding: 0;

      > a {
        width: 100%;
        position: static;
        border-radius: $px4 $px4 0 0;
      }

      .title {
        padding: $px10 $px15 0 $px15;
      }

      .date {
        padding: 0 $px15 $px15 $px15;
      }

      .act {
        position: static;
        text-align: center;
        padding: 0 $px15 $px15 $px15;
        transform: none;

        a {
          width: 100%;
        }
      }
    }
  }
}
</style>